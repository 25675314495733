
body {
    font-family: 'Montserrat', sans-serif;
}

h1 {
    font-size: 30px;
}

h3{
   font-weight: 700;
   text-align: center;
}


hr.gob{
    height: 5px!important;
    border-top: 1px solid var(--bs-secondary)!important;
    background-color: transparent;
    opacity: 1!important;
}

hr.gob::before{

    content: "";
    display: block;
    width: 10%;
    height: 5px;
    background-color: var(--bs-warning)!important;
    position: relative;
}

 #FormCitas {
    
    width: 75%;
    margin: auto;
    margin-top: 3%;
    margin-bottom: 5%;
    border-radius: 30px;
    box-shadow: 0px 0px 10px 2px black;
    font-weight: 600;

}
 

@media only all and (max-width: 551px) {

}


@media only all and (max-width: 550px) {

    #FormCitas {
        width:90%;
        margin: auto;
        margin-top: 5%;
        margin-bottom: 5%;
        
    }

}

