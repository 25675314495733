$ColorPrimario: #10312b;
$ColorSecundario: #ECF0F1;
$ColorSuccess: #1bd815;
$ColorInfor: #f0a20f;
$ColorWarning: #B38E5D;
$ColorDanger: #9D2449;
$ColorLight: #FFFFFF;
$ColorDark: #FF0000;


$theme-colors: (
  "primary": $ColorPrimario,
  "secondary": $ColorSecundario,
  "success": $ColorSuccess,
  "info": $ColorInfor,
  "warning": $ColorWarning,
  "danger": $ColorDanger,
  "light": $ColorLight,
  "dark": $ColorDark
);


$accordion-padding-y: 5rem;
$accordion-padding-x: 5rem;
$accordion-color: black;
$accordion-bg: $blue-100;

$accordion-button-active-color: black;
/* 
$accordion-border-width:                  $border-width;
$accordion-border-color:                  rgba($black, .125);
$accordion-border-radius:                 $border-radius;
$accordion-inner-border-radius:           subtract($accordion-border-radius, $accordion-border-width); */



$theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");

$carousel-control-color: black;
$carousel-control-width: 10%;
$carousel-indicator-width: 50px;
$carousel-indicator-height: 10px;
$carousel-control-icon-width: 4rem;
$carousel-control-opacity: .5;

$carousel-indicator-active-bg: white;
$carousel-caption-color: white;
$carousel-control-prev-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$carousel-control-color}'><path d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/></svg>");
$carousel-control-next-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$carousel-control-color}'><path d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>");

.react-datepicker__header.react-datepicker__header--time.react-datepicker__header--time--only::after {
  height: 50px;
  content: 'Horario y hora';
}

.react-datepicker-time__header {
  display: none;
}

/*Colores pestañas formulario*/

$nav-link-color: $ColorPrimario;
$nav-link-hover-color: $ColorPrimario;
$nav-link-disabled-color: $ColorPrimario;

$nav-tabs-link-active-bg: $ColorPrimario;
$nav-tabs-link-active-color: white;
$nav-tabs-link-active-border-color: #ddd;
$nav-tabs-border-radius: 0;



/* Pro sidebar variables  */
$sidebar-bg-color: $ColorPrimario;
$icon-bg-color: $ColorLight;
@import "~bootstrap/scss/bootstrap";
@import '~react-pro-sidebar/dist/scss/styles.scss';



.react-datepicker__day--highlighted-custom-2 {
  background: #B38E5D;
  pointer-events: none;
  color: black !important;

}

.react-datepicker__day--highlighted-custom-3 {
  background: #B38E5D;
  pointer-events: none;
  color: black !important;

}

.react-datepicker__day--highlighted-custom-3:hover {
  color: red;
  pointer-events: none;
  color: white;
}

.tooltip {
  color: red;
  background: yellow;
}

.tooltip .tooltiptext {
  color: red;
  background: yellow;
}

.tooltip:hover .tooltiptext {
  color: blue;
  background: yellow;

}